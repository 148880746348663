import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  constructor(private http:HttpClient) { }

  // url = "http://admin.codedote.in/api/getPortfolios";
  // url2 = "http://admin.codedote.in/api/getSinglePortfolio?portfolio_id=";

  url = "https://admin.codedote.in/api/getPortfolios";
  url2 = "https://admin.codedote.in/api/getSinglePortfolio?portfolio_id=";

  portfolio(){
    return this.http.get(this.url);
  }
  
  singlePortfolio(id){
    return this.http.get(this.url2+id);
  }
}
