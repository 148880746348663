import { Component, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Contact } from './contact'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  // display = true;

  title = 'codeDote | Contact Us';


  constructor(private titleService: Title,
    private meta: Meta,private http:HttpClient) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }

  ngOnInit() {

    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'CodeDote' });

    this.meta.updateTag({ name: 'description', content: 'Interested in CodeDote? just pick up the phone to call our sales team.' });

  }

  public message2:any;
  display = true;
  loader = false;
  // contact_url = "http://admin.codedote.in/api/submitContactForm";
  contact_url = "https://admin.codedote.in/api/submitContactForm";
  contact = new Contact('','','','','','');
  

  getContactData(formData){
    return this.http.post(this.contact_url,formData)
    .pipe(
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              // this.message2 = `${error.error.message}`;
              this.message2 = `${error.error.message}`;
          } else {
              this.message2 = this.getServerErrorMessage(error);
          }
          return throwError(this.message2);
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 422: {
            this.loader = false;
            return `Please fill all the required* fields`;        
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }
    }
}

  postData(){
    this.loader = true;

    this.getContactData(this.contact).subscribe(data=>{
      this.display = false;
      window.scrollTo(0, 400);
      });
  }

}
