import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { WebdevlopmentComponent } from './webdevlopment/webdevlopment.component';
import { MobiledevlopmentComponent } from './mobiledevlopment/mobiledevlopment.component';
import { DigitalmarketingComponent } from './digitalmarketing/digitalmarketing.component';
import { BrandingComponent } from './branding/branding.component';
import { ContactComponent } from './contact/contact.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ServicesComponent } from './services/services.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ModalModule } from 'ngx-bootstrap';
// Carousel Component
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CareerComponent } from './career/career.component';
// import { TestingpageComponent } from './testingpage/testingpage.component';
import { WebdesignComponent } from './webdesign/webdesign.component';
import { PopupmodelComponent } from './popupmodel/popupmodel.component';
// import { MdbFilterModule  } from './'
import { FormsModule } from '@angular/forms';
import { SeoComponent } from './seo/seo.component';
import { DomainRegistrationComponent } from './domain-registration/domain-registration.component';
import { PpcManagementComponent } from './ppc-management/ppc-management.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { OnlineReputationManagementComponent } from './online-reputation-management/online-reputation-management.component';
import { SocialMediaOptimizationComponent } from './social-media-optimization/social-media-optimization.component';
import { SocialMediaConsultancyComponent } from './social-media-consultancy/social-media-consultancy.component';
import { EmailMarketingComponent } from './email-marketing/email-marketing.component';
import { OnlineMarketingComponent } from './online-marketing/online-marketing.component';
import { ContentMarketingComponent } from './content-marketing/content-marketing.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import { GraphicDesigningComponent } from './graphic-designing/graphic-designing.component';
import { AnalyticsSolutionsComponent } from './analytics-solutions/analytics-solutions.component';
import { LogoDesigningComponent } from './logo-designing/logo-designing.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogHeaderComponent } from './blog-header/blog-header.component';
import { PublicFigureSectorComponent } from './public-figure-sector/public-figure-sector.component';
import { EntertaimentSectorComponent } from './entertaiment-sector/entertaiment-sector.component';
import { FoodSectorComponent } from './food-sector/food-sector.component';
import { TourismSectorComponent } from './tourism-sector/tourism-sector.component';
import { SubscribeUsComponent } from './subscribe-us/subscribe-us.component';
import { TestimonialComponent } from './testimonial/testimonial.component';

//translate ngx



//import fb chatbot
// import { FacebookModule } from 'ngx-facebook';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import { SubsComponent } from './subs/subs.component';
import { MapforportfolioComponent } from './mapforportfolio/mapforportfolio.component';
import { PortfoliosingleComponent } from './portfoliosingle/portfoliosingle.component';
import { TechnologyComponent } from './technology/technology.component';
import { ExpertiseComponent } from './expertise/expertise.component';
import { HireExpertsComponent } from './hire-experts/hire-experts.component';
import { FaqComponent } from './faq/faq.component';
import { PsdconversionComponent } from './psdconversion/psdconversion.component';
import { EstimationformComponent } from './estimationform/estimationform.component';
import { MarketingfunnelComponent } from './marketingfunnel/marketingfunnel.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';

import {HashLocationStrategy,LocationStrategy} from '@angular/common';
import { MegamenuComponent } from './megamenu/megamenu.component';
import { ServicecarouselComponent } from './servicecarousel/servicecarousel.component';
import { BannerComponent } from './banner/banner.component';
import { IntrovComponent } from './introv/introv.component';
import { FounderComponent } from './founder/founder.component';
import { SocialwallComponent } from './socialwall/socialwall.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    WebdevlopmentComponent,
    MobiledevlopmentComponent,
    DigitalmarketingComponent,
    BrandingComponent,
    ContactComponent,
    ServicesComponent,
    PortfolioComponent,
    CareerComponent,
    // TestingpageComponent,
    WebdesignComponent,
    PopupmodelComponent,
    SeoComponent,
    DomainRegistrationComponent,
    PpcManagementComponent,
    WebHostingComponent,
    OnlineReputationManagementComponent,
    SocialMediaOptimizationComponent,
    SocialMediaConsultancyComponent,
    EmailMarketingComponent,
    OnlineMarketingComponent,
    ContentMarketingComponent,
    SocialMediaMarketingComponent,
    GraphicDesigningComponent,
    AnalyticsSolutionsComponent,
    LogoDesigningComponent,
    BlogsComponent,
    BlogHeaderComponent,
    PublicFigureSectorComponent,
    EntertaimentSectorComponent,
    FoodSectorComponent,
    TourismSectorComponent,
    SubscribeUsComponent,
    TestimonialComponent,
    SubsComponent,
    MapforportfolioComponent,
    PortfoliosingleComponent,
    TechnologyComponent,
    ExpertiseComponent,
    HireExpertsComponent,
    FaqComponent,
    PsdconversionComponent,
    EstimationformComponent,
    MarketingfunnelComponent,
    NotfoundComponent,
    TermsandconditionsComponent,
    PrivacypolicyComponent,
    MegamenuComponent,
    ServicecarouselComponent,
    BannerComponent,
    IntrovComponent,
    FounderComponent,
    SocialwallComponent,
    // MdbFilterModule, 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MDBBootstrapModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule.forRoot(),
    // FacebookModule.forRoot(),
    // HttpModule,
    FormsModule,
    BrowserAnimationsModule,
    MatCardModule
    ],
  // providers: [],
  providers: [{provide:LocationStrategy, useClass:HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(){
    console.log('app module loaded');
  }
}
