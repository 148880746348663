export class Estimate {
    constructor(
        public name:string,
        public email:string,
        public country_code:string,
        public phone:string,
        public budget:string,
        public message:string
    )
    {}
}
