export class Contact {
    constructor(
        public first_name:string,
        public last_name:string,
        public email:string,
        public phone:string,
        public subject:string,
        public message:string
    )
    {}
}
