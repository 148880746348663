import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-email-marketing',
  templateUrl: './email-marketing.component.html',
  styleUrls: ['./email-marketing.component.css']
})
export class EmailMarketingComponent implements OnInit {
  title = 'codeDote | Email Marketing';

  constructor(private titleService: Title,
    private meta: Meta) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }

  ngOnInit() {
    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'Email Marketing, Marketing' });

    this.meta.updateTag({ name: 'description', content: 'Email marketing is a proven content marketing strategy for increasing brand awareness, growing website traffic, generating leads, and promoting products and services.' });

  }

}
