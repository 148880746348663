import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import * as AOS from 'aos'

@Component({
  selector: 'app-mobiledevlopment',
  templateUrl: './mobiledevlopment.component.html',
  styleUrls: ['./mobiledevlopment.component.css']
})
export class MobiledevlopmentComponent implements OnInit {

  title = 'codeDote | Mobile Development';

  constructor(private titleService: Title,
    private meta: Meta) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }

  ngOnInit() {
    AOS.init();

    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'Mobile Apps, Mobile Application, Mobile Development, Android, IOS' });

    this.meta.updateTag({ name: 'description', content: 'Mobile apps have become a great medium for digital interaction. Regardless of what your business is, a mobile application helps in getting and retaining the customers.' });

  }

}
