import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-psdconversion',
  templateUrl: './psdconversion.component.html',
  styleUrls: ['./psdconversion.component.css']
})
export class PsdconversionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
