export class Funnel {
    constructor(
        public first_name:string,
        public last_name:string,
        public company:string,
        public email:string,
        public phone:string,
        public website_type:string,
        public end_date:string,
        public budget_range:string,
        public user_type:string,
        public project_goal:string,
        public content_type:string,
        public content_provider:string,
        public examples:string,
        public slogan:string,
        public logo:string
    )
    {}
}
