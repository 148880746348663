import { Component, OnInit } from '@angular/core';
import { PortfolioService } from '../portfolio.service';

@Component({
  selector: 'app-subscribe-us',
  templateUrl: './subscribe-us.component.html',
  styleUrls: ['./subscribe-us.component.css']
})
export class SubscribeUsComponent implements OnInit {

  public email:any;

  // urlp = 'https://script.google.com/macros/s/AKfycbyWUg4wy04b0wTdulCqIigXUH3YnNhz_c9nC514YAfr4qAYByu1kjaMGkLegZvuCteYAg/exec';

  constructor(private portfolioService:PortfolioService) { }

  ngOnInit() {
  }

}
