export class Hiredata {
    constructor(
        public name:string,
        public email:string,
        public phone:string,
        public organization:string,
        public message:string,
        public services:string
    )
    {}
}
