import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-domain-registration',
  templateUrl: './domain-registration.component.html',
  styleUrls: ['./domain-registration.component.css']
})
export class DomainRegistrationComponent implements OnInit {

  title = 'codeDote | Domain Registration';

  constructor(private titleService: Title,
    private meta: Meta) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }

  ngOnInit() {

    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'Domain, Domain Registration, Domain Name , Website' });

    this.meta.updateTag({ name: 'description', content: 'Online success starts with a great Domain Name. The Domain is like an identity for your website, just like Name is for a human being and Brand name for your Business.' });

  }

}
