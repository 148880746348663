import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { PortfolioService } from '../portfolio.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css'],
})
export class PortfolioComponent implements OnInit {

  title = 'codeDote | Portolio';
  li:any;
  lis:any =[];
  loading = true;

  constructor(private titleService: Title,
    private meta: Meta,private portfolioService : PortfolioService, private router : Router) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }

  ngOnInit() {

    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'codeDote Portfolio, Portfolio, codeDote projects, webdevelopment project' });

    this.meta.updateTag({ name: 'description', content: 'This is portfolio page of codeDote. CodeDote has successfully completed several webdevelopment projects. ' });

    this.portfolioService.portfolio().subscribe(Response=>{
      this.li=Response;
      this.lis=this.li.data;
      // console.log(this.lis);
      this.loading = false;
    });
  }

  viewPortfolio(id:number){
    // console.log(id);
    this.router.navigate(['/portfoliosingle',id]);
  }

}
