import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-entertaiment-sector',
  templateUrl: './entertaiment-sector.component.html',
  styleUrls: ['./entertaiment-sector.component.css']
})
export class EntertaimentSectorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
