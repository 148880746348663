import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-megamenu',
  templateUrl: './megamenu.component.html',
  styleUrls: ['./megamenu.component.scss']
})
export class MegamenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
