import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Hiredata } from './hiredata';

@Component({
  selector: 'app-hire-experts',
  templateUrl: './hire-experts.component.html',
  styleUrls: ['./hire-experts.component.css']
})
export class HireExpertsComponent implements OnInit {

  constructor(private http:HttpClient) { }

  ngOnInit() {
  }

  public message2:any; //error message shown on component
  display = true; //hide form and show message on successfull submit
  loader = false; //loader waiting for response
  // hire_url = "http://admin.codedote.in/api/submitHiringForm"; 
  hire_url = "https://admin.codedote.in/api/submitHiringForm"; //form api url

  hiredata = new Hiredata('','','','','','');

  //post data to api and throw error returned
  getHireData(formData){
    return this.http.post(this.hire_url,formData)
    .pipe(
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              this.message2 = `${error.error.message}`;
          } else {
              this.message2 = this.getServerErrorMessage(error);
          }
          return throwError(this.message2);
      })
    );
  }

  //catch error message and display message
  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 422: {
            this.loader = false;
            // return `${error.error.errors.name}`;       
            return `Please fill all the required* fields`;     
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }
    }
}

//function on submit and data submit to api
  postData(){
    this.loader = true;
    this.getHireData(this.hiredata).subscribe(data=>{
      this.display = false;
      });
    // console.log(hiredata);
  }

}
