import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser'

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  title = 'Creative Social Media agency in India – CodeDote';

  constructor(private titleService: Title,
    private meta: Meta) {
    // meta.addTag({ name: 'keywords', content: 'CodeDote, Social Media, Creative, Social Media agency.' });
    // meta.addTag({ name: 'description', content: 'CodeDote everything you’d expect from a Social media agency: creative, passionate, flexible and personable. We deliver, scale and reach for brands to reach target audience.' })
  }

  ngOnInit() {
    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });
    this.meta.addTag({ name: 'robots', content: 'index, follow' });
    // this.meta.updateTag({ name:'keywords', content:'digital marketing services india, digital marketing company india, digital marketing plan, digital marketing agency, web development company in india, web development company india, mobile app development company india, mobile app development services india, graphics design company in India'});
    // this.meta.updateTag({ name:'description', content:'CodeDote Web Solutions, one stop solutions for web designing, development, web hosting, mobile apps, domain registration, e-commerce and search engine optimization services in India. Contact us now!'});
    this.meta.updateTag({ name: 'keywords', content: 'CodeDote, Social Media, Creative, Social Media agency' });
    this.meta.updateTag({ name: 'description', content: 'CodeDote is everything you’d expect from a Social media agency: creative, passionate, flexible and personable. We deliver, scale and reach for brands to reach target audience.' });
    // this.refresh;
  }

}
