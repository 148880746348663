import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-online-reputation-management',
  templateUrl: './online-reputation-management.component.html',
  styleUrls: ['./online-reputation-management.component.css']
})
export class OnlineReputationManagementComponent implements OnInit {

  title = 'codeDote | ORM';


  constructor(private titleService: Title,
    private meta: Meta) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }

  ngOnInit() {

    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'ORM, Online Reputation Management, Online Reputation , Reputation Management, Reputation' });

    this.meta.updateTag({ name: 'description', content: 'The concept of ORM (Online Reputation Management) in Digital Marketing plays an important role as it helps to get rid of negative reviews about a company and brand from search engines.' });


  }

}
