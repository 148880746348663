import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import * as AOS from 'aos';
import { PortfolioService } from '../portfolio.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss', './home.component.css']
})
export class HomeComponent implements OnInit {
  // codejune23
 // title = 'codeDote | Home';
  title = 'CodeDote | Home';
  li:any;
  lis:any =[];

  constructor(private titleService: Title,
    private meta: Meta,private portfolioService : PortfolioService) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }

  ngOnInit() {
    AOS.init();
    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });
    this.meta.addTag({ name: 'robots', content: 'index, follow' });
    // this.meta.updateTag({ name:'keywords', content:'digital marketing services india, digital marketing company india, digital marketing plan, digital marketing agency, web development company in india, web development company india, mobile app development company india, mobile app development services india, graphics design company in India'});
    // this.meta.updateTag({ name:'description', content:'CodeDote Web Solutions, one stop solutions for web designing, development, web hosting, mobile apps, domain registration, e-commerce and search engine optimization services in India. Contact us now!'});
    this.meta.updateTag({ name: 'keywords', content: 'CodeDote, Digital Marketing, SEO, Graphic Design, Web Development, Website/Websites, Marketing, Mobile Application' });
    this.meta.updateTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies. ' });
    // this.refresh;
    var urlParams = [];
    window.location.search.replace("?", "").split("&").forEach(function (e, i) {
      var p = e.split("=");
      urlParams[p[0]] = p[1];
    });
    // We have all the params now -> you can access it by name
    // console.log(urlParams["loaded"]);
    if (urlParams["loaded"]) { } else {
      let win = (window as any);
      // win.location.search = '?loaded=1';
      //win.location.reload('?loaded=1');
      win.location('?loaded=1');
    }

    // this.portfolioService.portfolio().subscribe(Response=>{
    //   this.li=Response;
    //   this.lis=this.li.data.data;
    //   console.log(this.lis);
    // });
  }


}
