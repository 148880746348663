import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import * as AOS from 'aos';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  title = 'Web Development and Digital Marketing company in India – CodeDote';

  constructor(private titleService: Title, private meta: Meta) {
    // meta.addTag({ name: 'keywords', content: 'ang1, any2, nsdsd2' });
    // meta.addTag({ name: 'description', content: 'this is testing area' })
  }

  ngOnInit() {
    AOS.init();
    this.titleService.setTitle(this.title);

    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'Web Development, Marketing, Development, Web Development and Digital Marketing Company in India' });

    this.meta.updateTag({ name: 'description', content: 'A dedicated Web development and Digital Marketing company in India catering to a perfect blend of creative, strategy-oriented, and innovative services to help you grow digitally.' });
  }

}
