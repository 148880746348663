import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { WebdevlopmentComponent } from './webdevlopment/webdevlopment.component';
import { MobiledevlopmentComponent } from './mobiledevlopment/mobiledevlopment.component';
import { DigitalmarketingComponent } from './digitalmarketing/digitalmarketing.component';
import { ContactComponent } from './contact/contact.component';
import { BrandingComponent } from './branding/branding.component';
import { ServicesComponent } from './services/services.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { CareerComponent } from './career/career.component';
// import { TestingpageComponent } from './testingpage/testingpage.component';
import { WebdesignComponent } from './webdesign/webdesign.component';
import { SeoComponent } from './seo/seo.component';
import { DomainRegistrationComponent } from './domain-registration/domain-registration.component';
import { PpcManagementComponent } from './ppc-management/ppc-management.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { OnlineReputationManagementComponent } from './online-reputation-management/online-reputation-management.component';
import { SocialMediaOptimizationComponent } from './social-media-optimization/social-media-optimization.component';
import { SocialMediaConsultancyComponent } from './social-media-consultancy/social-media-consultancy.component';
import { EmailMarketingComponent } from './email-marketing/email-marketing.component';
import { OnlineMarketingComponent } from './online-marketing/online-marketing.component';
import { ContentMarketingComponent } from './content-marketing/content-marketing.component';
import { SocialMediaMarketingComponent } from './social-media-marketing/social-media-marketing.component';
import { GraphicDesigningComponent } from './graphic-designing/graphic-designing.component';
import { AnalyticsSolutionsComponent } from './analytics-solutions/analytics-solutions.component';
import { LogoDesigningComponent } from './logo-designing/logo-designing.component';
import { BlogsComponent } from './blogs/blogs.component';
import { PublicFigureSectorComponent } from './public-figure-sector/public-figure-sector.component';
import { EntertaimentSectorComponent } from './entertaiment-sector/entertaiment-sector.component';
import { FoodSectorComponent } from './food-sector/food-sector.component';
import { TourismSectorComponent } from './tourism-sector/tourism-sector.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { PortfoliosingleComponent } from './portfoliosingle/portfoliosingle.component';
import { HireExpertsComponent } from './hire-experts/hire-experts.component';
import { FaqComponent } from './faq/faq.component';
import { PsdconversionComponent } from './psdconversion/psdconversion.component';
import { EstimationformComponent } from './estimationform/estimationform.component';
import { MarketingfunnelComponent } from './marketingfunnel/marketingfunnel.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { MegamenuComponent } from './megamenu/megamenu.component';

const routes: Routes = [

  

  // { path: '', redirectTo: '/home', pathMatch: 'full' },

  { path: '', component: HomeComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'career', component: CareerComponent },
  // { path: 'testingpage', component: TestingpageComponent },

  //services
  { path: 'services', component: ServicesComponent },
  { path: 'digitalmarketing', component: DigitalmarketingComponent },
  { path: 'mobiledevelopment', component: MobiledevlopmentComponent },
  { path: 'branding', component: BrandingComponent },
  { path: 'webdevelopment', component: WebdevlopmentComponent },
  { path: 'webdesign', component: WebdesignComponent },
  { path: 'seo', component: SeoComponent },
  { path: 'domain-registration', component: DomainRegistrationComponent },
  { path: 'ppc-management', component: PpcManagementComponent },
  { path: 'web-hosting', component: WebHostingComponent },
  { path: 'online-reputation-management', component: OnlineReputationManagementComponent },
  { path: 'social-media-optimization', component: SocialMediaOptimizationComponent },
  { path: 'social-media-consultancy', component: SocialMediaConsultancyComponent },
  { path: 'email-marketing', component: EmailMarketingComponent },
  { path: 'online-marketing', component: OnlineMarketingComponent },
  { path: 'content-marketing', component: ContentMarketingComponent },
  { path: 'social-media-marketing', component: SocialMediaMarketingComponent },
  { path: 'graphic-designing', component: GraphicDesigningComponent },
  { path: 'analytics-solutions', component: AnalyticsSolutionsComponent },
  { path: 'logo-designing', component: LogoDesigningComponent },
  { path: 'blog', component: BlogsComponent },
  { path: 'entertainment-sector', component: EntertaimentSectorComponent },
  { path: 'public-figure-sector', component: PublicFigureSectorComponent },
  { path: 'food-sector', component: FoodSectorComponent },
  { path: 'tourism-sector', component: TourismSectorComponent },
  { path: 'testimonial', component: TestimonialComponent},
  { path: 'portfoliosingle/:id', component: PortfoliosingleComponent },
  { path: 'hire-experts', component: HireExpertsComponent },
  { path: 'faq', component: FaqComponent},
  { path: 'psdconversion', component: PsdconversionComponent},
  { path: 'getaquote', component: EstimationformComponent},
  { path: 'funnel' ,component: MarketingfunnelComponent},
  { path: 'termsofuse', component:TermsandconditionsComponent},
  { path: 'privacypolicy', component:PrivacypolicyComponent},
  { path: 'megamenu', component: MegamenuComponent },

  { path: '**' ,component: NotfoundComponent},
  // { path: '', component: EmailMarketingComponent },
  // { path: '', component: EmailMarketingComponent },
  // { path: '', component: EmailMarketingComponent },
  // { path: '', component: EmailMarketingComponent },
  // { path: '', component: EmailMarketingComponent },
  // { path: '', component: EmailMarketingComponent },


];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
