import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tourism-sector',
  templateUrl: './tourism-sector.component.html',
  styleUrls: ['./tourism-sector.component.css']
})
export class TourismSectorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
