import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicecarousel',
  templateUrl: './servicecarousel.component.html',
  styleUrls: ['./servicecarousel.component.css']
})
export class ServicecarouselComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   
  }
  
}
