import { Component, OnInit,ElementRef, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Funnel } from './funnel'

@Component({
  selector: 'app-marketingfunnel',
  templateUrl: './marketingfunnel.component.html',
  styleUrls: ['./marketingfunnel.component.css']
})
export class MarketingfunnelComponent implements OnInit {

  @ViewChild('hidden',{static: false}) hidden: ElementRef;

  constructor(private titleService: Title,
    private meta: Meta,private http:HttpClient) { }

  ngOnInit() {

    this.titleService.setTitle("CodeDote | Marketing Funnel")
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'CodeDote' });

    this.meta.updateTag({ name: 'description', content: 'Interested in CodeDote? just pick up the phone to call our sales team.' });
  }

  public message2:any;
  display = true;
  loader = false;
  // funnel_url = "http://admin.codedote.in/api/submiFunnelForm";
  funnel_url = "https://admin.codedote.in/api/submiFunnelForm";

  funnel = new Funnel('','','','','','','','','','','','','','','');
  

  getFunnelData(formData){
    return this.http.post(this.funnel_url,formData)
    .pipe(
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              // this.message2 = `${error.error.message}`;
              this.message2 = `${error.error.message}`;
          } else {
              this.message2 = this.getServerErrorMessage(error);
          }
          return throwError(this.message2);
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 422: {
            // return `${error.error.errors.name}`+`${error.error.errors.email}`;
            this.loader = false;
            return `Please fill all the required* fields`;        
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }
    }
}

  postData(){
    this.loader = true;

    this.getFunnelData(this.funnel).subscribe(data=>{
      this.display = false;
      window.scrollTo(0, 400);
      });
    // console.log(funneldata);
  }

}
