import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
// import { FacebookService, InitParams } from 'ngx-facebook';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {


  title = 'codeDote';

  constructor( private titleService: Title,
    private meta: Meta, private renderer: Renderer2) {
    // meta.addTag({name:'keywords',content:'ang1, any2, nsdsd2'});
    // meta.addTag({name:'description',content:'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies'})


  }

  ngOnInit(): void {

    // this.initFacebookService();

    // this.titleService.setTitle(this.title)

    // this.meta.addTag({ name: 'author', content: 'CodeDote' });

    // this.meta.addTag({ name: 'robots', content: 'index, follow' });

    // this.meta.updateTag({ name: 'keywords', content: 'digital marketing services india, digital marketing company india, digital marketing plan, digital marketing agency, web development company in india, web development company india, mobile app development company india, mobile app development services india, graphics design company in India' });

    // this.meta.updateTag({ name: 'description', content: 'CodeDote Web Solutions, one stop solutions for web designing, development, web hosting, mobile apps, domain registration, e-commerce and search engine optimization services in India. Contact us now!' });

    let loader = this.renderer.selectRootElement('#page-loader');
    this.renderer.setStyle(loader, 'display', 'none');

  }

  // private initFacebookService(): void {
  //   const initParams: InitParams = { xfbml: true, version: 'v3.2' };
  //   this.facebookService.init(initParams);
  // }
}

