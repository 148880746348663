import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Estimate } from './estimate';

@Component({
  selector: 'app-estimationform',
  templateUrl: './estimationform.component.html',
  styleUrls: ['./estimationform.component.css']
})
export class EstimationformComponent implements OnInit {

  constructor(private http:HttpClient) { }

  ngOnInit() {
  }

  public message2:any;
  display = true;
  loader = false;
  // estimate_url = "http://admin.codedote.in/api/submiEstimationForm";
  estimate_url = "https://admin.codedote.in/api/submiEstimationForm";

  estimate = new Estimate('','','','','Select Budget Range*','');

  getEstimateData(formData){
    return this.http.post(this.estimate_url,formData)
    .pipe(
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              this.message2 = `${error.error.message}`;
          } else {
              this.message2 = this.getServerErrorMessage(error);
          }
          return throwError(this.message2);
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 422: {
            this.loader = false;
            // return `${error.error.errors.name}`; 
            return `Please fill all the required* fields`;           
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }
    }
}

  postData(){
    this.loader = true;
    this.getEstimateData(this.estimate).subscribe(data=>{
      this.display = false;
      });
    // console.log(estimatedata);
  }

}

