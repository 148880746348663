import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-web-hosting',
  templateUrl: './web-hosting.component.html',
  styleUrls: ['./web-hosting.component.css']
})
export class WebHostingComponent implements OnInit {

  title = 'codeDote | Web Hosting';


  constructor(private titleService: Title,
    private meta: Meta) {
    //meta.addTag({ name: 'keywords', content: 'CodeDote,Digital Marketing,SEO,Graphic Design,Web Development,Website,Websites,Marketing,Mobile Application' });
    //meta.addTag({ name: 'description', content: 'CodeDote is a profound Software Development company with a firm vision of fuelling up your business strategies.' })
  }


  ngOnInit() {
    this.titleService.setTitle(this.title)
    this.meta.addTag({ name: 'author', content: 'CodeDote' });

    this.meta.addTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ name: 'keywords', content: 'Web Hosting , Hosting Services , Website, Hosting , Server , Cloud Hosting' });

    this.meta.updateTag({ name: 'description', content: 'Web Development as a whole is an essential component to create your online presence. Web development refers to building, creating, and maintaining websites.' });

  }

}
