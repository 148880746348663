import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router,ParamMap } from '@angular/router';
import{ PortfolioService } from '../portfolio.service';

@Component({
  selector: 'app-portfoliosingle',
  templateUrl: './portfoliosingle.component.html',
  styleUrls: ['./portfoliosingle.component.css']
})
export class PortfoliosingleComponent implements OnInit {

  portfolioid:any;
  li:any;
  lis:any =[];
  params:any = [];

  constructor(private activatedRoute:ActivatedRoute,private router:Router,private portfolioService:PortfolioService) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params:ParamMap)=>{
      this.portfolioid= parseInt(params.get('id'));
    });

    this.portfolioService.singlePortfolio(this.portfolioid).subscribe(Response=>{
      this.li=Response;
      this.lis=this.li.data;
      // this.params = JSON.parse(JSON.stringify(this.lis.dynamic_params));
      this.params = this.lis.dynamic_params;
      // console.log(this.lis.dynamic_params);

    });
  }
}
