import { Component, OnInit } from '@angular/core';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { appConfig } from '../app.config';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Contact } from './contact'

@Component({
  selector: 'app-popupmodel',
  templateUrl: './popupmodel.component.html',
  styleUrls: ['./popupmodel.component.css']
})
export class PopupmodelComponent implements OnInit {
  title = 'CodeDote';

  constructor(
    private http: HttpClient
    ) { }


    // url = "https://script.google.com/macros/s/AKfycbzRAoeFmlGv5FpEi4eMgR7SHslEIYEL5u_bEWFb7dtsreOURlSsZ1Pl4g/exec"


    refresh(){
      window.location.reload();
    }

  ngOnInit() {
  }

  public message2:any;
  display = true;
  loader = false;
  // contact_url = "http://admin.codedote.in/api/submitContactForm";
  contact_url = "https://admin.codedote.in/api/submitContactForm";

  contact = new Contact('','','','','','');
  

  getContactData(formData){
    return this.http.post(this.contact_url,formData)
    .pipe(
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              // this.message2 = `${error.error.message}`;
              this.message2 = `${error.error.message}`;
          } else {
              this.message2 = this.getServerErrorMessage(error);
          }
          return throwError(this.message2);
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 422: {
            this.loader = false;
            return `Please fill all the required* fields`;        
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }
    }
}

  postData(){
    this.loader = true;

    this.getContactData(this.contact).subscribe(data=>{
      this.display = false;
      window.scrollTo(0, 400);
      });
  }


}
