import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Subscribe } from './subscribe';

@Component({
  selector: 'app-subs',
  templateUrl: './subs.component.html',
  styleUrls: ['./subs.component.css']
})
export class SubsComponent implements OnInit {

  // url = 'https://script.google.com/macros/s/AKfycbyWUg4wy04b0wTdulCqIigXUH3YnNhz_c9nC514YAfr4qAYByu1kjaMGkLegZvuCteYAg/exec';

  // res(){
  //   alert("Your Email has been submitted!")
  //  window.location.reload();
 
  // }
  // emailsub(){
  //    console.log("submitted");
  // }

  constructor(private http:HttpClient) {}

  ngOnInit() {
  }

  public message:any;
  display = true;
  loader = false;
  // subscribe_url = "http://admin.codedote.in/api/subscriber";
  subscribe_url = "https://admin.codedote.in/api/subscriber";

  subscribe = new Subscribe('');

  getSubscribeEmail(formData){
    return this.http.post(this.subscribe_url,formData)
    .pipe(
      catchError(error => {
          if (error.error instanceof ErrorEvent) {
              this.message = `${error.error.message}`;
          } else {
              this.message = this.getServerErrorMessage(error);
          }

          return throwError(this.message);
      })
    );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    switch (error.status) {
        case 422: {
            this.loader = false;
            return `Please enter valid Email`;        
        }
        default: {
            return `Unknown Server Error: ${error.message}`;
        }
    }
}

  subscribeUs(){
    this.loader = true;
    this.getSubscribeEmail(this.subscribe).subscribe(data=>{
      // console.log(data);
      this.display = false;
      // setTimeout(
      //   function(){ 
      //   location.reload(); 
      //   }, 2000);
      });
    // this.email=emailValue;
    // console.log(this.email.email);
  }


}
